module.exports = {
  // App Info
  appstore_link         : "http://itunes.apple.com/us/app/fnitestore/id1457539146?mt=8",                                  // Enter App Store URL.
  playstore_link        : null,                                  // Enter Google Play Store URL.
  google_analytics_ID   : "UA-16831466-24",                                        // Enter Google Analytics ID or ""
  presskit_download_link: null,                                  // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file). 
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://fnitestore.com",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "FNite Store",
  app_price             : "Free",
  app_description       : "Get notified when the daily store updates!",
  app_keywords          : ["fortnite", "battle royale", "companion app", "fnite", "fnite store", "daily store"],

  // Personal Info
  your_name              : "Josh Kowarsky",
  your_link              : "https://joshkowarsky.com",
  your_city              : "Vancouver, BC",
  email_address          : "info@fnitestore.com",
  facebook_username      : null,
  instagram_username     : null,
  twitter_username       : "@J0shK",
  github_username        : null,
  youtube_username       : null,

  // Features List
  features               : [
    {
      title: "Get notified",
      description:
        "FNite Store sends you notifications when the daily store changes!",
      fontawesome_icon_name: "bell",
    },
    {
      title: "Manage your skins",
      description:
        "Mark cosmetics as want or have",
      fontawesome_icon_name: "star",
    },
    {
      title: "Get the latest news",
      description:
        "Displays the latest news from Epic",
      fontawesome_icon_name: "newspaper",
    },
    {
      title: "Leaked skins",
      description:
        "See what skins are coming to the store before they come out",
      fontawesome_icon_name: "unlock",
    },
    {
      title: "Weekly cheat sheets",
      description:
        "Get hints on how to complete challenges",
      fontawesome_icon_name: "map",
    },
    {
      title: "Challenge list",
      description:
        "Manage your challenges",
      fontawesome_icon_name: "list",
    },
  ],
  header_background             : "#392759",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(92, 94, 203, 0.50)",
  device_color                  : "black",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "#E8F0FF",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#1d63ea",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#392759",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
